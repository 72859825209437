<template>
  <div class="text-center">
    <v-menu
      :nudge-width="150"
      min-width="230"
      max-width="350"
      offset-y
      inset-x
      :close-on-content-click="false"
      content-class="user-profile-menu-content"
    >
      <template v-slot:activator="{ on }">
        <v-avatar size="38px" color="secondary" rounded v-on="on" v-ripple>
          <span class="white--text font-weight-medium">{{ initialien }}</span>
        </v-avatar>
      </template>
      <v-card>
        <v-list nav>
          <v-list-item link>
            <v-avatar size="38" color="secondary" rounded class="me-3" ripple>
              <span class="white--text font-weight-medium">{{
                initialien
              }}</span>
            </v-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{ displayName }}
              </v-list-item-title>
              <v-list-item-subtitle
                ><small class="text--disabled">{{
                  availability
                }}</small></v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

          <v-divider class="pb-2"></v-divider>

          <!-- <v-list-item link :to="{ name: 'settings-profile' }">
            <v-list-item-icon class="me-2">
              <v-icon size="22"> mdi-account </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Profil</v-list-item-title>
          </v-list-item> -->

          <!-- <v-list-item link :to="{ name: 'notification-center' }">
            <v-list-item-icon class="me-2">
              <v-icon size="22"> mdi-bell </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Mitteilungen</v-list-item-title>
            <v-list-item-action>
              <v-badge inline color="error" content="2"> </v-badge>
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider> -->

          <v-list-item link :to="{ name: 'settings-profile' }">
            <v-list-item-icon class="me-3">
              <v-icon size="22"> mdi-cog </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Einstellungen</v-list-item-title>
          </v-list-item>

          <v-divider class="pb-2"></v-divider>

          <v-list-item link href="https://docs.mira-one.com">
            <v-list-item-icon class="me-3">
              <v-icon size="22"> mdi-face-agent </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Support</v-list-item-title>
          </v-list-item>

          <v-list-item link href="https://docs.mira-one.com">
            <v-list-item-icon class="me-3">
              <v-icon size="22"> mdi-bookshelf </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Dokumentation</v-list-item-title>
          </v-list-item>

          <v-divider class="pb-2"></v-divider>

          <v-list-item link @click="logout()">
            <v-list-item-icon class="me-3">
              <v-icon size="22"> mdi-logout-variant </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Abmelden</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { USER } from "@/store/modules.js";
import { SIGN_OUT } from "@/store/action-types.js";
export default {
  name: "app-bar-user-menu",
  data() {
    return {
      availability: "Verfügbar",
    };
  },
  computed: {
    displayName() {
      return (
        this.$store.state.user.user.firstName +
        " " +
        this.$store.state.user.user.lastName
      );
    },
    initialien() {
      const namesArray =
        this.displayName == undefined ? "" : this.displayName.trim().split(" ");
      if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
      else
        return `${namesArray[0].charAt(0)}${namesArray[
          namesArray.length - 1
        ].charAt(0)}`;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch(`${USER}${SIGN_OUT}`);
    },
  },
};
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
