import * as fb from "@/firebase";
import router from "@/router/index";
// import router from "../router/index";

import {
  GET_USER_GROUPS,
  CREATE_USER_GROUP,
  GET_USER_LIST,
  GET_MEMBERSHIPS_PRIVATE,
  GET_INVITES,
  INVITE_USER,
  GET_ROLES,
  CREATE_ROLE,
  CREATE_ROLES,
  UPDATE_ROLE,
  UPDATE_STANDARD_USER_GROUP,
  UPDATE_DEFAULT_DIVISION,
  UPDATE_USER_GROUP,
  DELETE_USER_GROUP,
  DELETE_INVITE,
  UPDATE_INVITE,
  UPDATE_INVITE_STATUS,
  UPDATE_MEMBERSHIP_ROLE,
  DELETE_ROLE,
  RESET_ADMIN,
  GET_LOGS,
  GET_PRIVACY_POLICY_VERSIONS,
  // CREATE_PRIVACY_POLICY_VERSION,
} from "../action-types";
import {
  SET_LOADING,
  SET_ROLES,
  SET_STANDARD_USER_GROUP,
  SET_USER_GROUPS,
  SET_USER_LIST,
  SET_MEMBERSHIPS_PRIVATE,
  SET_INVITES,
  SET_LOGS,
  SET_PRIVACY_POLICY_VERSIONS,
} from "../mutation-types";

export default {
  namespaced: true,

  state: {
    loading: false,

    membershipsPublic: [],
    membershipsPrivate: [],
    invites: [],

    userGroups: [],

    roles: [],

    extensions: [],

    privacyPolicyVersions: [],
    // Audit
    logs: [],
  },

  mutations: {
    [SET_LOADING](state, payload) {
      state.loading = payload;
    },

    [SET_USER_LIST](state, payload) {
      const sortedPayload = payload.sort((a, b) =>
        a.user.lastName.localeCompare(b.user.lastName)
      );
      state.membershipsPublic = sortedPayload;
    },

    [SET_MEMBERSHIPS_PRIVATE](state, payload) {
      const sortedPayload = payload.sort((a, b) =>
        a.user.lastName.localeCompare(b.user.lastName)
      );
      state.membershipsPrivate = sortedPayload;
    },

    [SET_INVITES](state, payload) {
      state.invites = payload;
    },

    [SET_USER_GROUPS](state, payload) {
      state.userGroups = payload;
    },

    [SET_ROLES](state, payload) {
      state.roles = payload;
    },

    [SET_STANDARD_USER_GROUP](state, payload) {
      state.benutzergruppenStandard = payload;
    },

    [SET_LOGS](state, payload) {
      state.logs = payload;
    },

    [SET_PRIVACY_POLICY_VERSIONS](state, payload) {
      state.privacyPolicyVersions = payload;
    },
  },

  actions: {
    [RESET_ADMIN]({ commit }) {
      commit(SET_USER_LIST, []);
      commit(SET_INVITES, []);
      commit(SET_USER_GROUPS, []);
      commit(SET_ROLES, []);
      commit(SET_STANDARD_USER_GROUP, {});
    },
    // Konfiguration

    // Allgemein

    // Erscheinungsbild

    // Organisationsstruktur (SOON)

    // Abrechnung (SOON)

    // Benutzer

    // Benutzerkonten
    [GET_USER_LIST]({ commit }, payload) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      const data = [];
      const baseReference = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("membershipsPublic");
      var reference = baseReference;
      if (payload.queryIsFiltered) {
        switch (payload.query.type) {
          case "active":
            reference = baseReference
              .where("access.type", "==", "member")
              .where("access.status", "in", ["activated", "deactivated"]);
            break;
          case "archived":
            reference = baseReference
              .where("access.type", "==", "member")
              // All members where memberUntilDate is after/greater than startDate of dataset
              .where(
                "user.memberUntil",
                ">",
                fb.Timestamp.fromDate(new Date(payload.query.memberUntil))
              );
            break;
          default:
            // query.type is not defined
            break;
        }
      }

      reference
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const object = {
              user: {
                displayName: doc.data().user.displayName,
                firstName: doc.data().user.firstName,
                lastName: doc.data().user.lastName,
                photoUrl: doc.data().user.photoUrl,
                dutyPosition: {
                  title: doc.data().user.dutyPosition.title,
                  shortTitle: doc.data().user.dutyPosition.shortTitle,
                  sortKey: doc.data().user.dutyPosition.sortKey,
                  id: doc.data().user.dutyPosition.id,
                  badgeUrl: {
                    size70x70: doc.data().user.dutyPosition.badgeUrl.size70x70,
                    size125x125:
                      doc.data().user.dutyPosition.badgeUrl.size125x125,
                  },
                },
                division: {
                  title: doc.data().user.division.title,
                  shortTitle: doc.data().user.division.shortTitle,
                  sortKey: doc.data().user.division.sortKey,
                  id: doc.data().user.division.id,
                },
                rank: {
                  title: doc.data().user.rank.title,
                  shortTitle: doc.data().user.rank.shortTitle,
                  sortKey: doc.data().user.rank.sortKey,
                  id: doc.data().user.rank.id,
                  badgeUrl: {
                    size70x70: doc.data().user.rank.badgeUrl.size70x70,
                    size125x125: doc.data().user.rank.badgeUrl.size125x125,
                  },
                },
                memberSince: doc
                  .data()
                  .user.memberSince.toDate()
                  .toLocaleDateString("de-DE", options),
                memberSinceToDate: doc.data().user.memberSince.toDate(),
                memberUntil: doc.data().user.memberUntil
                  ? doc
                      .data()
                      .user.memberUntil.toDate()
                      .toLocaleDateString("de-DE", options)
                  : "",
                memberUntilToDate: doc.data().user.memberUntil.toDate(),
                uid: doc.data().user.uid,
              },
              group: {
                title: doc.data().group.title,
                id: doc.data().group.id,
                sortKey: doc.data().group.sortKey,
              },
              assignments: {
                ids: doc.data().assignments.ids,
                data: doc.data().assignments.data,
              },

              relevantSkills: doc.data().relevantSkills,
              fillablePositions: doc.data().fillablePositions,
              access: {
                status: doc.data().access.status,
                type: doc.data().access.type,
              },
            };
            data.push(object);
          });
          commit(SET_USER_LIST, data);
        })
        .catch(function (error) {
          console.error("Error getting document:", error);
          return false;
        });
    },

    [GET_MEMBERSHIPS_PRIVATE]({ commit }, payload) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      const data = [];
      const baseReference = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("membershipsPrivate");
      var reference = baseReference;
      if (payload.queryIsFiltered) {
        switch (payload.query.type) {
          case "active":
            reference = baseReference
              .where("access.type", "==", "member")
              .where("access.status", "in", ["activated", "deactivated"]);
            break;
          case "archived":
            console.log(payload.query.memberUntil);
            reference = baseReference
              .where("access.type", "==", "member")
              // All members where memberUntilDate is after/greater than startDate of dataset
              .where(
                "user.memberUntil",
                ">",
                fb.Timestamp.fromDate(new Date(payload.query.memberUntil))
              );
            break;
          default:
            // query.type is not defined
            break;
        }
      }

      reference
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const object = {
              ...doc.data(),
              user: {
                displayName: doc.data().user.displayName,
                photoUrl: doc.data().user.photoUrl,
                dutyPosition: {
                  title: doc.data().user.dutyPosition.title,
                  shortTitle: doc.data().user.dutyPosition.shortTitle,
                  sortKey: doc.data().user.dutyPosition.sortKey,
                  id: doc.data().user.dutyPosition.id,
                  badgeUrl: {
                    size70x70: doc.data().user.dutyPosition.badgeUrl.size70x70,
                    size125x125:
                      doc.data().user.dutyPosition.badgeUrl.size125x125,
                  },
                },
                division: {
                  title: doc.data().user.division.title,
                  shortTitle: doc.data().user.division.shortTitle,
                  sortKey: doc.data().user.division.sortKey,
                  id: doc.data().user.division.id,
                },
                rank: {
                  title: doc.data().user.rank.title,
                  shortTitle: doc.data().user.rank.shortTitle,
                  sortKey: doc.data().user.rank.sortKey,
                  id: doc.data().user.rank.id,
                  badgeUrl: {
                    size70x70: doc.data().user.rank.badgeUrl.size70x70,
                    size125x125: doc.data().user.rank.badgeUrl.size125x125,
                  },
                },
                memberSince: doc
                  .data()
                  .user.memberSince.toDate()
                  .toLocaleDateString("de-DE", options),
                memberSinceToDate: doc.data().user.memberSince.toDate(),
                memberUntil: doc.data().user.memberUntil
                  ? doc
                      .data()
                      .user.memberUntil.toDate()
                      .toLocaleDateString("de-DE", options)
                  : "",
                memberUntilToDate: doc.data().user.memberUntil.toDate(),
                uid: doc.data().user.uid,
              },
            };
            data.push(object);
          });
          commit(SET_MEMBERSHIPS_PRIVATE, data);
        })
        .catch(function (error) {
          console.error("Error getting document:", error);
          return false;
        });
    },

    // Einladungen
    [GET_INVITES]({ commit }, payload) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      const data = [];
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("invites")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const object = {
              email: doc.data().email,
              group: {
                title: doc.data().group.title,
                id: doc.data().group.id,
              },
              invite: {
                status: doc.data().invite.status,
                type: doc.data().invite.type,
              },
              organizationId: payload.organizationId,
              meta: {
                id: doc.data().meta.id,
                createdAt: doc
                  .data()
                  .meta.createdAt.toDate()
                  .toLocaleDateString("de-DE", options),
              },
            };
            data.push(object);
          });
          commit(SET_INVITES, data);
        })
        .catch(function (error) {
          console.error("Error getting document:", error);
          return false;
        });
    },
    [INVITE_USER]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("userGroups")
        .doc();
      docRef
        .set({
          title: payload.title,
          sortKey: payload.sortKey,
          description: payload.description,
          organizationId: payload.organizationId,
          meta: {
            id: docRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        })
        .then(function () {
          router.push({
            name: "admin-users-tab-groups",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [UPDATE_INVITE]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("invites")
        .doc(payload.itemId)
        .update({
          email: payload.email,
          "group.title": payload.group.title,
          "group.id": payload.group.id,
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .then(function () {
          router.push({
            name: "admin-users-tab-accounts",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [UPDATE_INVITE_STATUS]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("invites")
        .doc(payload.itemId)
        .update({
          "invite.status": payload.invite.status,
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .then(function () {
          router.push({
            name: "admin-users-tab-accounts",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [DELETE_INVITE]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("invites")
        .doc(payload.itemId);
      docRef
        .delete()
        .then(function () {
          router.push({
            name: "admin-users-tab-accounts",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    [UPDATE_MEMBERSHIP_ROLE]({ commit }, payload) {
      commit(SET_LOADING, true);
      var rolesIds;
      if (payload.roles === null || undefined) {
        rolesIds = [];
      } else {
        rolesIds = payload.roles;
      }
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("membershipsPrivate")
        .doc(payload.itemId)
        .update({
          "access.roles.individualRolesIds": rolesIds.concat("user"),
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    // Benutzergruppen
    [GET_USER_GROUPS]({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const data = [];
        fb.organizationCollection
          .doc(payload.organizationId)
          .collection("userGroups")
          .orderBy("sortKey", "asc")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const object = {
                title: doc.data().title,
                sortKey: doc.data().sortKey,
                description: doc.data().description,
                meta: {
                  id: doc.data().meta.id,
                },
              };
              data.push(object);
            });
            commit(SET_USER_GROUPS, data);
            resolve(querySnapshot);
          })
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              console.log("Error getting document:", error);
              reject(error);
            }
          );
      });
    },
    [CREATE_USER_GROUP]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("userGroups")
        .doc();
      docRef
        .set({
          title: payload.title,
          sortKey: payload.sortKey,
          description: payload.description,
          roleIds: [],
          organizationId: payload.organizationId,
          meta: {
            id: docRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        })
        .then(function () {
          router.push({
            name: "admin-users-tab-groups",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [UPDATE_USER_GROUP]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("userGroups")
        .doc(payload.itemId)
        .update({
          title: payload.title,
          description: payload.description,
          sortKey: payload.sortKey,
          roleIds: payload.roleIds.includes("user")
            ? payload.roleIds
            : payload.roleIds.concat("user"),
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .then(function () {
          router.push({
            name: "admin-users-tab-groups",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));

      var batch = fb.db.batch();

      const array = payload.membersIDArray;
      array.forEach(function (item) {
        console.log(item);
        const batchRef = fb.db
          .collection("organizations")
          .doc(payload.organizationId)
          .collection("membershipsPrivate")
          .doc(item);

        batch.update(batchRef, {
          group: {
            title: payload.title,
            sortKey: payload.sortKey,
            id: payload.itemId,
          },
          "access.roles.groupRolesIds": payload.roleIds.concat("user"),
        });
      });
      batch
        .commit()
        .then(function () {
          console.log("done");
        })
        .then(function () {
          router.push({
            name: "admin-users-tab-groups",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));

      commit(SET_LOADING, false);
    },
    [DELETE_USER_GROUP]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("userGroups")
        .doc(payload.itemId);
      docRef
        .delete()
        .then(function () {
          router.push({
            name: "admin-users-tab-groups",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    // Sicherheit

    // Allgemein

    // Rollen
    [GET_ROLES]({ commit }, payload) {
      const data = [];
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("roles")
        .orderBy("isLocked")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const object = {
              title: doc.data().title,
              description: doc.data().description,
              permissions: doc.data().permissions,
              isLocked: doc.data().isLocked,
              disabled: doc.data().disabled,
              meta: {
                id: doc.data().meta.id,
              },
            };
            data.push(object);
          });
          commit(SET_ROLES, data);
        })
        .catch(function (error) {
          console.error("Error getting document:", error);
          return false;
        });
    },
    [CREATE_ROLE]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("roles")
        .doc();

      docRef
        .set({
          title: payload.title,
          description: payload.description,
          permissions: payload.permissions,
          isLocked: false,
          disabled: false,
          organizationId: payload.organizationId,
          meta: {
            id: docRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        })
        .then(function () {
          router.push({
            name: "admin-security-tab-roles",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    [CREATE_ROLES]({ commit }, payload) {
      var batch = fb.db.batch();
      const batchRef = fb.db
        .collection("organizations")
        .doc(payload.organizationId)
        .collection("roles");

      payload.roles.forEach(function (role) {
        const newDocRef = batchRef.doc();
        const object = {
          ...role,
          organizationId: payload.organizationId,
          meta: {
            id: newDocRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        };
        batch.set(newDocRef, object);
      });
      batch
        .commit()
        .then(() => {
          return commit(SET_LOADING, false);
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },

    [UPDATE_ROLE]({ commit }, payload) {
      commit(SET_LOADING, true);
      if (payload.meta.isLocked) {
        alert(
          "Diese Rolle wird vom System geliefert und kann nicht bearbeitet werden."
        );
      } else {
        fb.organizationCollection
          .doc(payload.organizationId)
          .collection("roles")
          .doc(payload.itemId)
          .update({
            title: payload.title,
            description: payload.description,
            permissions: payload.permissions,
            "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
            "meta.lastUpdatedBy": fb.auth.currentUser.uid,
          })
          .then(function () {
            router.push({
              name: "admin-security-tab-roles",
              params: { organizationId: payload.organizationId },
            });
          })
          .catch((error) => console.log(error));
      }
      commit(SET_LOADING, false);
    },
    [DELETE_ROLE]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("roles")
        .doc(payload.itemId);
      docRef
        .delete()
        .then(function () {
          router.push({
            name: "admin-security-tab-roles",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [UPDATE_STANDARD_USER_GROUP]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .update({
          "config.security.defaultUserGroupId":
            payload.security.defaultUserGroupId,
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [UPDATE_DEFAULT_DIVISION]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .update({
          "config.memberManagement.defaultDivisionId":
            payload.defaultDivisionId,
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    // Zuordnung

    // Datenschutz

    // API (SOON)

    // Erweiterungen

    // Audit
    [GET_LOGS]({ commit }, payload) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      const data = [];
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("logs")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const object = {
              log: {
                id: doc.data().log.id,
                timestamp: doc
                  .data()
                  .log.timestamp.toDate()
                  .toLocaleDateString("de-DE", options),
                timestampFirebaseFormat: doc.data().log.timestamp,
                trigger: doc.data().log.trigger,
                // uid: doc.data().log.uid,
                details: doc.data().log.details,
              },
              meta: {
                id: doc.data().meta.id,
                createdAt: doc
                  .data()
                  .meta.createdAt.toDate()
                  .toLocaleDateString("de-DE", options),
              },
            };
            data.push(object);
          });
          commit(SET_LOGS, data);
        })
        .catch(function (error) {
          console.error("Error getting document:", error);
          return false;
        });
    },

    [GET_PRIVACY_POLICY_VERSIONS]({ commit }, payload) {
      const data = [];
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("privacyPolicyVersions")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const object = {
              versionNumber: doc.data().versionNumber,
              summary: doc.data().summary,
              content: doc.data().content,
              meta: {
                id: doc.data().meta.id,
                createdAt: doc.data().meta.createdAt,
              },
            };
            data.push(object);
          });
          commit(SET_PRIVACY_POLICY_VERSIONS, data);
        })
        .catch(function (error) {
          console.error("Error getting document:", error);
          return false;
        });
    },
  },

  getters: {},
};
