import * as fb from "@/firebase";
import router from "@/router/index";
// import { auth } from "../../firebase";
// import { db } from "@/firebase";

import {
  GET_PERSONNEL_RECORDS,
  UPDATE_PERSONNEL_RECORD,
  GET_DEADLINES,
  CREATE_DEADLINE,
  // CREATE_DEADLINES,
  UPDATE_DEADLINE,
  // DELETE_DEADLINE,
  GET_ASSIGNMENTS,
  CREATE_ASSIGNMENT,
  UPDATE_ASSIGNMENT,
  UPDATE_ASSIGNMENTS,
  DELETE_ASSIGNMENT,
  GET_SKILL_GROUPS,
  CREATE_SKILL_GROUP,
  CREATE_SKILL_GROUPS,
  UPDATE_SKILL_GROUP,
  DELETE_SKILL_GROUP,
  GET_RANKS,
  CREATE_RANK,
  CREATE_RANKS,
  UPDATE_RANK,
  // DELETE_RANK,
  GET_DUTY_POSITIONS,
  CREATE_DUTY_POSITION,
  CREATE_DUTY_POSITIONS,
  UPDATE_DUTY_POSITION,
  // DELETE_DUTY_POSITION,
  GET_SKILLS,
  CREATE_SKILL,
  CREATE_SKILLS,
  UPDATE_SKILL,
  DELETE_SKILL,
  GET_COURSES,
  CREATE_COURSE,
  CREATE_COURSES,
  UPDATE_COURSE,
  DELETE_COURSE,
  GET_COURSE_GROUPS,
  CREATE_COURSE_GROUP,
  CREATE_COURSE_GROUPS,
  UPDATE_COURSE_GROUP,
  DELETE_COURSE_GROUP,
  UPDATE_MEMBER_MANAGEMENT_CONFIG,
  GET_DIVISIONS,
  CREATE_DIVISION,
  CREATE_DIVISIONS,
  UPDATE_DIVISION,
  // DELETE_DIVISION,
  // DELETE_SKILL
  GET_PERFORMANCE_TARGETS,
  GET_PERFORMANCE_TARGET_RESULTS,
  CREATE_PERFORMANCE_TARGET,
  UPDATE_PERFORMANCE_TARGET,
  CREATE_PERFORMANCE_TARGET_EXCEPTION,
  UPDATE_PERFORMANCE_TARGET_EXCEPTION,
} from "../action-types";
import {
  SET_PERSONNEL_RECORDS,
  SET_ASSIGNMENTS,
  SET_PERFORMANCE_TARGETS,
  SET_PERFORMANCE_TARGET_RESULTS,
  SET_SKILL_GROUPS,
  SET_SKILLS,
  SET_RANKS,
  SET_DUTY_POSITIONS,
  SET_HONORS,
  SET_DEADLINES,
  SET_DIVISIONS,
  SET_COURSE_GROUPS,
  SET_COURSES,
  SET_LOADING,
} from "../mutation-types";

export default {
  namespaced: true,

  state: {
    membershipsPublic: [],
    membershipsPrivate: [],

    personnelRecords: [],

    deadlines: [],
    performanceTargets: [],
    performanceTargetResults: [],
    assignments: [],
    skillGroups: [],
    skills: [],
    ranks: [],
    dutyPositions: [],
    honors: [],
    divisions: [],
    courses: [],
    courseGroups: [],
  },

  mutations: {
    [SET_LOADING](state, payload) {
      state.loading = payload;
    },
    [SET_PERSONNEL_RECORDS](state, payload) {
      state.personnelRecords = payload;
    },
    [SET_ASSIGNMENTS](state, payload) {
      state.assignments = payload;
    },
    [SET_DEADLINES](state, payload) {
      state.deadlines = payload;
    },
    [SET_PERFORMANCE_TARGETS](state, payload) {
      state.performanceTargets = payload;
    },
    [SET_PERFORMANCE_TARGET_RESULTS](state, payload) {
      state.performanceTargetResults = payload;
    },
    [SET_SKILL_GROUPS](state, payload) {
      state.skillGroups = payload;
    },
    [SET_SKILLS](state, payload) {
      state.skills = payload;
    },
    [SET_RANKS](state, payload) {
      state.ranks = payload;
    },
    [SET_DUTY_POSITIONS](state, payload) {
      state.dutyPositions = payload;
    },
    [SET_HONORS](state, payload) {
      state.honors = payload;
    },
    [SET_DIVISIONS](state, payload) {
      state.divisions = payload;
    },
    [SET_COURSE_GROUPS](state, payload) {
      state.courseGroups = payload;
    },
    [SET_COURSES](state, payload) {
      state.courses = payload;
    },
  },

  actions: {
    [GET_PERSONNEL_RECORDS]({ commit }, payload) {
      const data = [];
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("personnelRecords_general")
        .where("user.type", "==", "member")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            data.push(doc.data());
          });
          commit(SET_PERSONNEL_RECORDS, data);
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    [UPDATE_PERSONNEL_RECORD]({ commit }, payload) {
      commit(SET_LOADING, true);

      const batch = fb.db.batch();
      const baseRef = fb.organizationCollection.doc(payload.organizationId);

      const baseData = {
        meta: {
          lastUpdatedAt: fb.FieldValue.serverTimestamp(),
          lastUpdatedBy: fb.auth.currentUser.uid,
        },
      };

      const personnelRecords_general = payload.personnelRecords_general;
      const personnelRecords_contact = payload.personnelRecords_contact;
      const personnelRecords_chronicle = payload.personnelRecords_chronicle;
      const personnelRecords_certificates =
        payload.personnelRecords_certificates;
      const personnelRecords_emergencyContacts =
        payload.personnelRecords_emergencyContacts;
      const personnelRecords_youthGroup = payload.personnelRecords_youthGroup;
      // const personnelRecords_accounting = payload.personnelRecords_accounting;
      // const personnelRecords_other = payload.personnelRecords_other;

      const collections = [
        "personnelRecords_general",
        "personnelRecords_contact",
        "personnelRecords_chronicle",
        "personnelRecords_certificates",
        "personnelRecords_emergencyContacts",
        "personnelRecords_youthGroup",
        "personnelRecords_accounting",
        "personnelRecords_other",
      ];

      collections.forEach((collection) => {
        const ref = baseRef.collection(collection).doc(payload.itemId);
        if (collection === "personnelRecords_general") {
          batch.set(ref, {
            ...baseData,
            ...personnelRecords_general,
          });
        } else if (collection === "personnelRecords_contact") {
          batch.set(ref, {
            ...baseData,
            ...personnelRecords_contact,
          });
        } else if (collection === "personnelRecords_chronicle") {
          batch.set(ref, {
            ...baseData,
            events: personnelRecords_chronicle.events,
          });
        } else if (collection === "personnelRecords_certificates") {
          batch.set(ref, {
            ...baseData,
            skills: {
              data: personnelRecords_certificates.skills.data,
              ids: personnelRecords_certificates.skills.data.map(
                (course) => course.id
              ),
            },
            courses: {
              data: personnelRecords_certificates.courses.data,
              ids: personnelRecords_certificates.courses.data.map(
                (course) => course.id
              ),
            },
            /* INSERT DATA HERE */
          });
        } else if (collection === "personnelRecords_emergencyContacts") {
          batch.set(ref, {
            ...baseData,
            emergencyContacts:
              personnelRecords_emergencyContacts.emergencyContacts,
            /* INSERT DATA HERE */
          });
        } else if (collection === "personnelRecords_youthGroup") {
          batch.set(ref, {
            ...baseData,
            guardian1: {
              salutation: personnelRecords_youthGroup.guardian1.salutation,
              letterSalutation:
                personnelRecords_youthGroup.guardian1.letterSalutation,
              title: personnelRecords_youthGroup.guardian1.title,
              firstName: personnelRecords_youthGroup.guardian1.firstName,
              lastName: personnelRecords_youthGroup.guardian1.lastName,
              contact: {
                phone: personnelRecords_youthGroup.guardian1.contact.phone,
                mobile: personnelRecords_youthGroup.guardian1.contact.mobile,
                email: personnelRecords_youthGroup.guardian1.contact.email,
              },
              adress: {
                street: personnelRecords_youthGroup.guardian1.adress.street,
                houseNumber:
                  personnelRecords_youthGroup.guardian1.adress.houseNumber,
                zipCode: personnelRecords_youthGroup.guardian1.adress.zipCode,
                city: personnelRecords_youthGroup.guardian1.adress.city,
              },
            },
            guardian2: {
              salutation: personnelRecords_youthGroup.guardian2.salutation,
              letterSalutation:
                personnelRecords_youthGroup.guardian2.letterSalutation,
              title: personnelRecords_youthGroup.guardian2.title,
              firstName: personnelRecords_youthGroup.guardian2.firstName,
              lastName: personnelRecords_youthGroup.guardian2.lastName,
              contact: {
                phone: personnelRecords_youthGroup.guardian2.contact.phone,
                mobile: personnelRecords_youthGroup.guardian2.contact.mobile,
                email: personnelRecords_youthGroup.guardian2.contact.email,
              },
              adress: {
                street: personnelRecords_youthGroup.guardian2.adress.street,
                houseNumber:
                  personnelRecords_youthGroup.guardian2.adress.houseNumber,
                zipCode: personnelRecords_youthGroup.guardian2.adress.zipCode,
                city: personnelRecords_youthGroup.guardian2.adress.city,
              },
            },
            school: {
              name: personnelRecords_youthGroup.school.name,
              contactPerson: personnelRecords_youthGroup.school.contactPerson,
              contact: {
                phone: personnelRecords_youthGroup.school.contact.phone,
                email: personnelRecords_youthGroup.school.contact.email,
              },
              adress: {
                street: personnelRecords_youthGroup.school.adress.street,
                houseNumber:
                  personnelRecords_youthGroup.school.adress.houseNumber,
                zipCode: personnelRecords_youthGroup.school.adress.zipCode,
                city: personnelRecords_youthGroup.school.adress.city,
              },
            },
            note: null,
          });
        } else if (collection === "personnelRecords_accounting") {
          batch.set(ref, {
            ...baseData,
            /* INSERT DATA HERE */
          });
        } else if (collection === "personnelRecords_other") {
          batch.set(ref, {
            ...baseData,
            /* INSERT DATA HERE */
          });
        }
      });

      return batch
        .commit()
        .then(function () {
          console.log("done");
        })
        .then(function () {
          commit(SET_LOADING, false);
          router.push({
            name: "member-management-assignments",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
    },

    [GET_ASSIGNMENTS]({ commit }, payload) {
      const data = [];
      const baseReference = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("assignments");
      var reference = baseReference;
      if (payload.queryIsFiltered) {
        switch (payload.query.type) {
          case "excludeHiddenAssignments":
            reference = baseReference.where("isHidden", "==", false);
            break;
          default:
            // query.type is not defined
            break;
        }
      }
      reference
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            data.push(doc.data());
          });
          commit(SET_ASSIGNMENTS, data);
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    [CREATE_ASSIGNMENT]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("assignments")
        .doc();
      docRef
        .set({
          title: payload.title,
          description: payload.description,
          isHidden: payload.isHidden,
          contact: {
            emails: payload.contact.emails,
            links: payload.contact.links,
          },
          color: payload.color,
          icon: payload.icon,
          priorityScore: payload.priorityScore,
          workloadScore: payload.workloadScore,
          assignees: [],
          assigneesUids: [],
          analysis: {
            minAssignees: payload.analysis.minAssignees,
            maxAssignees: payload.analysis.maxAssignees,
            currentAssignees: payload.assignees.length,
            currentLeaders: payload.assignees.filter((item) => item.isLeader)
              .length,
            isShown: payload.analysis.isShown,
          },
          organizationId: payload.organizationId,
          meta: {
            id: docRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        })
        .then(function () {
          router.push({
            name: "member-management-assignments",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [UPDATE_ASSIGNMENT]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("assignments")
        .doc(payload.itemId)
        .update({
          title: payload.title,
          description: payload.description,
          isHidden: payload.isHidden,
          contact: {
            emails: payload.contact.emails,
            links: payload.contact.links,
          },
          color: payload.color,
          icon: payload.icon,
          priorityScore: payload.priorityScore,
          workloadScore: payload.workloadScore,
          assignees: payload.assignees,
          assigneesUids: payload.assignees.map((assignee) => assignee.user.uid),
          analysis: {
            minAssignees: payload.analysis.minAssignees,
            maxAssignees: payload.analysis.maxAssignees,
            currentAssignees: payload.assignees.length,
            currentLeaders: payload.assignees.filter(
              (assignee) => assignee.isLeader
            ).length,
            isShown: payload.analysis.isShown,
          },

          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .then(function () {
          router.push({
            name: "member-management-assignments",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [UPDATE_ASSIGNMENTS]({ commit }, payload) {
      commit(SET_LOADING, true);
      var batch = fb.db.batch();

      payload.assignments.forEach(function (assignment) {
        const batchRef = fb.db
          .collection("organizations")
          .doc(payload.organizationId)
          .collection("assignments")
          .doc(assignment.meta.id);

        batch.update(batchRef, {
          assignees: assignment.assignees,
          assigneesUids: assignment.assignees.map(
            (assignee) => assignee.user.uid
          ),
          "analysis.currentAssignees": assignment.assignees.length,
          "analysis.currentLeaders": assignment.assignees.filter(
            (assignee) => assignee.isLeader
          ).length,

          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        });
      });
      batch
        .commit()
        .then(function () {
          console.log("done");
        })
        .then(function () {
          router.push({
            name: "member-management-assignments",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [DELETE_ASSIGNMENT]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("assignments")
        .doc(payload.itemId);
      docRef
        .delete()
        .then(function () {
          router.push({
            name: "member-management-assignments",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [GET_DEADLINES]({ commit }, payload) {
      const data = [];
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("deadlines")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            data.push(doc.data());
          });
          commit(SET_DEADLINES, data);
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    [CREATE_DEADLINE]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("deadlines")
        .doc();
      docRef
        .set({
          title: payload.title,
          shortTitle: payload.shortTitle,
          sortKey: payload.sortKey,
          description: payload.description,
          applicableTo: {
            divisionIds: payload.applicableTo.divisionIds,
            userGroupIds: payload.applicableTo.userGroupIds,
          },
          dueType: payload.dueType,
          watchers: payload.watchers,

          organizationId: payload.organizationId,
          meta: {
            id: docRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        })
        .then(function () {
          router.push({
            name: "member-management-deadline-monitoring-tab-overview",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [UPDATE_DEADLINE]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("deadlines")
        .doc(payload.itemId)
        .update({
          title: payload.title,
          shortTitle: payload.shortTitle,
          sortKey: payload.sortKey,
          description: payload.description,
          "applicableTo.divisionIds": payload.applicableTo.divisionIds,
          "applicableTo.userGroupIds": payload.applicableTo.userGroupIds,
          dueType: payload.dueType,
          watchers: payload.watchers,
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .then(function () {
          router.push({
            name: "member-management-deadline-monitoring-tab-overview",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [GET_RANKS]({ commit }, payload) {
      const data = [];
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("ranks")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            data.push(doc.data());
          });
          commit(SET_RANKS, data);
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    [CREATE_RANK]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("ranks")
        .doc();
      docRef
        .set({
          title: payload.title,
          shortTitle: payload.shortTitle,
          isActive: payload.isActive,
          sortKey: payload.sortKey,
          badgeUrl: {
            size70x70: null,
            size125x125: null,
          },
          organizationId: payload.organizationId,
          meta: {
            id: docRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        })
        .then(function () {
          router.push({
            name: "member-management-config-ranks",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [CREATE_RANKS]({ commit }, payload) {
      var batch = fb.db.batch();
      const batchRef = fb.db
        .collection("organizations")
        .doc(payload.organizationId)
        .collection("ranks");

      payload.ranks.forEach(function (rank) {
        const newDocRef = batchRef.doc();
        const object = {
          ...rank,
          organizationId: payload.organizationId,
          meta: {
            id: newDocRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        };
        batch.set(newDocRef, object);
      });
      batch
        .commit()
        .then(() => {
          return commit(SET_LOADING, false);
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    [UPDATE_RANK]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("ranks")
        .doc(payload.itemId)
        .update({
          title: payload.title,
          shortTitle: payload.shortTitle,
          isActive: payload.isActive,
          sortKey: payload.sortKey,
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .then(function () {
          router.push({
            name: "member-management-config-ranks",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [GET_DIVISIONS]({ commit }, payload) {
      const data = [];
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("divisions")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            data.push(doc.data());
          });
          commit(SET_DIVISIONS, data);
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    [CREATE_DIVISION]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("divisions")
        .doc();
      docRef
        .set({
          title: payload.title,
          shortTitle: payload.shortTitle,
          isActive: payload.isActive,
          sortKey: payload.sortKey,
          organizationId: payload.organizationId,
          meta: {
            id: docRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        })
        .then(function () {
          router.push({
            name: "member-management-config-divisions",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [CREATE_DIVISIONS]({ commit }, payload) {
      var batch = fb.db.batch();
      const batchRef = fb.db
        .collection("organizations")
        .doc(payload.organizationId)
        .collection("divisions");

      payload.divisions.forEach(function (division) {
        const newDocRef = batchRef.doc();
        const object = {
          ...division,
          organizationId: payload.organizationId,
          meta: {
            id: newDocRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        };
        batch.set(newDocRef, object);
      });
      batch
        .commit()
        .then(() => {
          return commit(SET_LOADING, false);
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    [UPDATE_DIVISION]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("divisions")
        .doc(payload.itemId)
        .update({
          title: payload.title,
          shortTitle: payload.shortTitle,
          isActive: payload.isActive,
          sortKey: payload.sortKey,
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .then(function () {
          router.push({
            name: "member-management-config-divisions",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [GET_DUTY_POSITIONS]({ commit }, payload) {
      const data = [];
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("dutyPositions")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            data.push(doc.data());
          });
          commit(SET_DUTY_POSITIONS, data);
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    [CREATE_DUTY_POSITION]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("dutyPositions")
        .doc();
      docRef
        .set({
          title: payload.title,
          shortTitle: payload.shortTitle,
          isActive: payload.isActive,
          sortKey: payload.sortKey,
          badgeUrl: {
            size70x70: null,
            size125x125: null,
          },
          organizationId: payload.organizationId,
          meta: {
            id: docRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        })
        .then(function () {
          router.push({
            name: "member-management-config-duty-positions",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [CREATE_DUTY_POSITIONS]({ commit }, payload) {
      var batch = fb.db.batch();
      const batchRef = fb.db
        .collection("organizations")
        .doc(payload.organizationId)
        .collection("dutyPositions");

      payload.dutyPositions.forEach(function (dutyPosition) {
        const newDocRef = batchRef.doc();
        const object = {
          ...dutyPosition,
          organizationId: payload.organizationId,
          meta: {
            id: newDocRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        };
        batch.set(newDocRef, object);
      });
      batch
        .commit()
        .then(() => {
          return commit(SET_LOADING, false);
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    [UPDATE_DUTY_POSITION]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("dutyPositions")
        .doc(payload.itemId)
        .update({
          title: payload.title,
          shortTitle: payload.shortTitle,
          isActive: payload.isActive,
          sortKey: payload.sortKey,
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .then(function () {
          router.push({
            name: "member-management-config-duty-positions",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [GET_SKILL_GROUPS]({ commit }, payload) {
      const data = [];
      return fb.organizationCollection
        .doc(payload.organizationId)
        .collection("skillGroups")
        .orderBy("sortKey", "asc")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            data.push(doc.data());
          });
          commit(SET_SKILL_GROUPS, data);
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    [CREATE_SKILL_GROUP]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("skillGroups")
        .doc();
      docRef
        .set({
          title: payload.title,
          isHiddenInProfile: payload.isHiddenInProfile,
          skillsAreEqual: payload.skillsAreEqual,
          color: payload.color,
          icon: payload.icon,
          sortKey: payload.sortKey,
          organizationId: payload.organizationId,
          meta: {
            id: docRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        })
        .then(function () {
          router.push({
            name: "member-management-config-skills",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [CREATE_SKILL_GROUPS]({ commit }, payload) {
      var batch = fb.db.batch();
      const batchRef = fb.db
        .collection("organizations")
        .doc(payload.organizationId)
        .collection("skillGroups");

      payload.skillGroups.forEach(function (skillGroup) {
        const newDocRef = batchRef.doc();
        const object = {
          ...skillGroup,
          organizationId: payload.organizationId,
          meta: {
            id: newDocRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        };
        batch.set(newDocRef, object);
      });
      batch
        .commit()
        .then(() => {
          return commit(SET_LOADING, false);
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    [UPDATE_SKILL_GROUP]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("skillGroups")
        .doc(payload.itemId)
        .update({
          title: payload.title,
          isHiddenInProfile: payload.isHiddenInProfile,
          skillsAreEqual: payload.skillsAreEqual,
          color: payload.color,
          icon: payload.icon,
          sortKey: payload.sortKey,
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .then(function () {
          router.push({
            name: "member-management-config-skills",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [DELETE_SKILL_GROUP]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("skillGroups")
        .doc(payload.itemId);
      docRef
        .delete()
        .then(function () {
          router.push({
            name: "member-management-config-skills",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    [GET_SKILLS]({ commit }, payload) {
      const data = [];
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("skills")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            data.push(doc.data());
          });
          commit(SET_SKILLS, data);
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    [CREATE_SKILL]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("skills")
        .doc();
      docRef
        .set({
          title: payload.title,
          shortTitle: payload.shortTitle,
          sortKey: payload.sortKey,
          badgeUrl: {
            size200x200: null,
            size400x400: null, // TODO
          },
          group: {
            title: payload.group.title,
            sortKey: payload.group.sortKey,
            icon: payload.group.icon,
            color: payload.group.color,
            isHiddenInProfile: payload.group.isHiddenInProfile,
            skillsAreEqual: payload.group.skillsAreEqual,
            id: payload.group.id,
          },
          requirements: {
            description: payload.requirements.description,
            courses: payload.requirements.courses,
          },
          importTags: payload.importTags,
          organizationId: payload.organizationId,
          meta: {
            id: docRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        })
        .then(function () {
          router.push({
            name: "member-management-config-skills",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [CREATE_SKILLS]({ commit }, payload) {
      var batch = fb.db.batch();
      const batchRef = fb.db
        .collection("organizations")
        .doc(payload.organizationId)
        .collection("skills");

      payload.skills.forEach(function (skill) {
        const newDocRef = batchRef.doc();
        const object = {
          ...skill,
          organizationId: payload.organizationId,
          meta: {
            id: newDocRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        };
        batch.set(newDocRef, object);
      });
      batch
        .commit()
        .then(() => {
          return commit(SET_LOADING, false);
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    [UPDATE_SKILL]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("skills")
        .doc(payload.itemId)
        .update({
          title: payload.title,
          shortTitle: payload.shortTitle,
          sortKey: payload.sortKey,
          group: {
            title: payload.group.title,
            sortKey: payload.group.sortKey,
            icon: payload.group.icon,
            color: payload.group.color,
            isHiddenInProfile: payload.group.isHiddenInProfile,
            skillsAreEqual: payload.group.skillsAreEqual,
            id: payload.group.id,
          },
          requirements: {
            description: payload.requirements.description,
            courses: payload.requirements.courses,
          },
          importTags: payload.importTags,
          organizationId: payload.organizationId,
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .then(function () {
          router.push({
            name: "member-management-config-skills",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [DELETE_SKILL]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("skills")
        .doc(payload.itemId);
      docRef
        .delete()
        .then(function () {
          router.push({
            name: "member-management-config-skills",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    [GET_COURSE_GROUPS]({ commit }, payload) {
      const data = [];
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("courseGroups")
        .orderBy("sortKey", "asc")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            data.push(doc.data());
          });
          commit(SET_COURSE_GROUPS, data);
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    [CREATE_COURSE_GROUP]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("courseGroups")
        .doc();
      docRef
        .set({
          title: payload.title,
          color: payload.color,
          icon: payload.icon,
          sortKey: payload.sortKey,
          organizationId: payload.organizationId,
          meta: {
            id: docRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        })
        .then(function () {
          router.push({
            name: "member-management-config-courses",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [CREATE_COURSE_GROUPS]({ commit }, payload) {
      var batch = fb.db.batch();
      const batchRef = fb.db
        .collection("organizations")
        .doc(payload.organizationId)
        .collection("courseGroups");

      payload.courseGroups.forEach(function (courseGroup) {
        const newDocRef = batchRef.doc();
        const object = {
          ...courseGroup,
          organizationId: payload.organizationId,
          meta: {
            id: newDocRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        };
        batch.set(newDocRef, object);
      });
      batch
        .commit()
        .then(() => {
          return commit(SET_LOADING, false);
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    [UPDATE_COURSE_GROUP]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("courseGroups")
        .doc(payload.itemId)
        .update({
          title: payload.title,
          isHiddenInProfile: payload.isHiddenInProfile,

          color: payload.color,
          icon: payload.icon,
          sortKey: payload.sortKey,
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .then(function () {
          router.push({
            name: "member-management-config-courses",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [DELETE_COURSE_GROUP]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("courseGroups")
        .doc(payload.itemId);
      docRef
        .delete()
        .then(function () {
          router.push({
            name: "member-management-config-courses",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    [GET_COURSES]({ commit }, payload) {
      const data = [];
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("courses")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            data.push(doc.data());
          });
          commit(SET_COURSES, data);
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    [CREATE_COURSE]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("courses")
        .doc();
      docRef
        .set({
          title: payload.title,
          description: payload.description,
          type: payload.type,
          group: {
            title: payload.group.title,
            sortKey: payload.group.sortKey,
            icon: payload.group.icon,
            color: payload.group.color,
            id: payload.group.id,
          },
          importTags: payload.importTags,
          organizationId: payload.organizationId,
          meta: {
            id: docRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        })
        .then(function () {
          router.push({
            name: "member-management-config-courses",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [CREATE_COURSES]({ commit }, payload) {
      var batch = fb.db.batch();
      const batchRef = fb.db
        .collection("organizations")
        .doc(payload.organizationId)
        .collection("courses");

      payload.courses.forEach(function (course) {
        const newDocRef = batchRef.doc();
        const object = {
          ...course,
          organizationId: payload.organizationId,
          meta: {
            id: newDocRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        };
        batch.set(newDocRef, object);
      });
      batch
        .commit()
        .then(() => {
          return commit(SET_LOADING, false);
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    [UPDATE_COURSE]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("courses")
        .doc(payload.itemId)
        .update({
          title: payload.title,
          description: payload.description,
          type: payload.type,
          importTags: payload.importTags,
          group: {
            title: payload.group.title,
            sortKey: payload.group.sortKey,
            icon: payload.group.icon,
            color: payload.group.color,
            id: payload.group.id,
          },

          organizationId: payload.organizationId,
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .then(function () {
          router.push({
            name: "member-management-config-courses",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [DELETE_COURSE]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("courses")
        .doc(payload.itemId);
      docRef
        .delete()
        .then(function () {
          router.push({
            name: "member-management-config-courses",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [UPDATE_MEMBER_MANAGEMENT_CONFIG]({ commit }, payload) {
      commit(SET_LOADING, true);
      let updateData = {};
      if (payload.type === "onlyAssignmentsThresholdsConfig") {
        updateData = {
          "config.memberManagement.assignments.thresholds.minWorkloadCount":
            payload.thresholds.minWorkloadCount,
          "config.memberManagement.assignments.thresholds.maxWorkloadCount":
            payload.thresholds.maxWorkloadCount,
          "config.memberManagement.assignments.thresholds.minAssignmentsCount":
            payload.thresholds.minAssignmentsCount,
          "config.memberManagement.assignments.thresholds.maxAssignmentsCount":
            payload.thresholds.maxAssignmentsCount,
        };
      } else if (payload.type === "onlySkillsConfig") {
        updateData = {
          "config.memberManagement.badgeDisplay.primarySkillGroupId":
            payload.badgeDisplay.primarySkillGroupId,
        };
      }
      fb.organizationCollection
        .doc(payload.organizationId)
        .update({
          ...updateData,
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    [GET_PERFORMANCE_TARGETS]({ commit }, payload) {
      const data = [];
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("performanceTargets")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            data.push(doc.data());
          });
          commit(SET_PERFORMANCE_TARGETS, data);
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    [GET_PERFORMANCE_TARGET_RESULTS]({ commit }, payload) {
      const data = [];
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("performanceTargetResults")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            data.push(doc.data());
          });
          commit(SET_PERFORMANCE_TARGET_RESULTS, data);
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    [CREATE_PERFORMANCE_TARGET]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("performanceTargets")
        .doc();
      docRef
        .set({
          title: payload.title,
          shortTitle: payload.shortTitle,
          sortKey: payload.sortKey,
          isActive: payload.isActive,
          validFrom: { timestamp: payload.validFrom.timestamp },
          validUntil: { timestamp: payload.validUntil.timestamp },
          applicableDivisionIds: payload.applicableDivisionIds,
          monitoringInterval: {
            unit: payload.monitoringInterval.unit,
            value: payload.monitoringInterval.value,
          },
          dataVariables: payload.dataVariables,
          conditions: {
            logicalOperator: payload.conditions.logicalOperator,
            conditions: payload.conditions.conditions,
          },
          organizationId: payload.organizationId,
          meta: {
            id: docRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        })
        .then(function () {
          router.push({
            name: "member-management-performance-targets",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [UPDATE_PERFORMANCE_TARGET]({ commit }, payload) {
      commit(SET_LOADING, true);
      console.log(payload.validFrom.timestamp);
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("performanceTargets")
        .doc(payload.itemId)
        .update({
          title: payload.title,
          shortTitle: payload.shortTitle,
          sortKey: payload.sortKey,
          isActive: payload.isActive,
          validFrom: { timestamp: payload.validFrom.timestamp },
          validUntil: { timestamp: payload.validUntil.timestamp },
          applicableDivisionIds: payload.applicableDivisionIds,
          monitoringInterval: {
            unit: payload.monitoringInterval.unit,
            value: payload.monitoringInterval.value,
          },
          dataVariables: payload.dataVariables,
          conditions: {
            logicalOperator: payload.conditions.logicalOperator,
            conditions: payload.conditions.conditions,
          },
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .then(function () {
          router.push({
            name: "member-management-performance-targets",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [CREATE_PERFORMANCE_TARGET_EXCEPTION]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("performanceTargetExceptions")
        .doc();
      docRef
        .set({
          reason: payload.reason,
          user: {
            uid: payload.user.uid,
            membershipId: payload.user.membershipId,
            displayName: payload.user.displayName,
            firstName: payload.user.firstName,
            lastName: payload.user.lastName,
          },
          isActive: payload.isActive,
          validFrom: { timestamp: payload.validFrom.timestamp },
          validUntil: { timestamp: payload.validUntil.timestamp },
          applicablePerformanceTargets: {
            ids: payload.applicablePerformanceTargets.ids,
            data: payload.applicablePerformanceTargets.data,
          },
          organizationId: payload.organizationId,
          meta: {
            id: docRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        })
        .then(function () {
          router.push({
            name: "member-management-performance-targets-exceptions",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
    [UPDATE_PERFORMANCE_TARGET_EXCEPTION]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("performanceTargetExceptions")
        .doc(payload.itemId)
        .update({
          reason: payload.reason,
          isActive: payload.isActive,
          validFrom: { timestamp: payload.validFrom.timestamp },
          validUntil: { timestamp: payload.validUntil.timestamp },
          applicablePerformanceTargets: {
            ids: payload.applicablePerformanceTargets.ids,
            data: payload.applicablePerformanceTargets.data,
          },
          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .then(function () {
          router.push({
            name: "member-management-performance-targets-exceptions",
            params: { organizationId: payload.organizationId },
          });
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
  },

  getters: {},
};
