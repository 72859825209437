<template>
  <div>
    <v-list-item v-if="organization" class="d-flex justify-center">
      <!-- <v-list-item-action>
        <v-btn icon color="red darken-2">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-list-item-action> -->
      <!-- <v-list-item-avatar> -->
      <v-avatar
        :size="miniMode ? 32 : 48"
        :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'"
        rounded
      >
        <v-img
          :src="
            alternativeAvatarUrl ||
            (organization &&
              organization.config.appearance.avatarUrl.size75x75) ||
            ''
          "
        ></v-img>
      </v-avatar>
      <!-- </v-list-item-avatar> -->
      <v-list-item-content
        v-show="!miniMode"
        class="ml-4"
        style="margin-right: 20px !important"
      >
        <v-list-item-title class="font-weight-medium">
          {{ organization.displayName }}
        </v-list-item-title>
        <v-list-item-subtitle v-if="availabilityModuleActivated">
          <v-chip
            label
            small
            color="green lighten-4"
            class="font-weight-bold green--text text--darken-4 mr-2"
            ><v-icon small left>mdi-check-circle</v-icon
            >{{ organization.count.totalAvailable }}</v-chip
          >
          <v-chip
            v-if="organization.count.totalInEvent > 0"
            label
            small
            color="blue lighten-4"
            class="font-weight-bold blue--text text--darken-4 mr-2"
            ><v-icon small left>mdi-fire-truck</v-icon
            >{{ organization.count.totalInEvent }}</v-chip
          >
          <v-chip
            label
            small
            color="red lighten-4"
            class="font-weight-bold red--text text--darken-4 mr-2"
            ><v-icon small left>mdi-close-circle</v-icon
            >{{ organization.count.totalNotAvailable }}</v-chip
          >
        </v-list-item-subtitle>
      </v-list-item-content>
      <!-- <v-list-item-action>
      <OptionsMenu
        iconOrientation="horizontal"
        :actions="menuActions"
      ></OptionsMenu>
    </v-list-item-action> -->
    </v-list-item>
  </div>
</template>

<script>
// import OptionsMenu from "@/components/_system/helpers/OptionsMenu.vue";

export default {
  name: "organization-info-list-item",
  components: {
    // OptionsMenu,
  },
  props: {
    alternativeAvatarUrl: {
      type: String,
      required: false,
    },
    miniMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuActions: [
        {
          title: "Datenschutzerklärung der Organisation",
          icon: "mdi-shield-lock",
          function: this.openPrivacyPolicy,
        },
      ],
    };
  },
  computed: {
    availabilityModuleActivated() {
      return this.$store.getters["organization/checkExtensionAvailabilityById"](
        "availability"
      );
    },

    organization() {
      return this.$store.state.organization.activeOrganization;
    },
  },
  methods: {
    openPrivacyPolicy() {
      this.$router.push({
        name: "organization-privacy-policy",
        params: {
          organizationId: this.$route.params.organizationId,
        },
      });
    },
  },
};
</script>
