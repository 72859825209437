import * as fb from "@/firebase";
// import { auth } from "../../firebase";
// import { db } from "@/firebase";
import {
  GET_REPORTS,
  GET_ARCHIVED_REPORTS,
  // CREATE_REPORT,
  // UPDATE_REPORT,
  GET_REPORT_TYPES,
  CREATE_REPORT_TYPE,
  CREATE_REPORT_TYPES,
  UPDATE_REPORT_TYPE,
  DELETE_REPORT_TYPE,
} from "../action-types";
import {
  SET_REPORTS,
  SET_ARCHIVED_REPORTS,
  SET_REPORT_TYPES,
  SET_LOADING,
} from "../mutation-types";
export default {
  namespaced: true,

  state: {
    reports: [],
    archivedReports: [],
    dutyTypes: [],
    loading: false,
  },

  mutations: {
    [SET_REPORTS](state, payload) {
      state.reports = payload;
    },
    [SET_ARCHIVED_REPORTS](state, payload) {
      state.archivedReports = payload;
    },
    [SET_REPORT_TYPES](state, payload) {
      state.dutyTypes = payload;
    },
    [SET_LOADING](state, payload) {
      state.loading = payload;
    },
  },

  actions: {
    [GET_REPORTS]({ commit }, payload) {
      const reports = [];
      var limit = 350;
      if (payload.paginationLimit != undefined) {
        limit = payload.paginationLimit;
      }

      var baseReference = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("dutyReports")
        .where("status", "in", [
          "toBeProcessed",
          "inCorrection",
          "inProgress",
          "toBeReviewed",
          "approved",
          // "rejected",
        ]);
      let reference = baseReference; // base case queries all entries
      if (payload.query.queryIsFiltered) {
        if (payload.query.filterByUid) {
          reference = reference.where("assignedTo.uid", "==", payload.uid);
        }
        if (payload.query.onlyOwn) {
          reference = reference.where(
            "assignedTo.uid",
            "==",
            fb.auth.currentUser.uid
          );
        }
      }

      return (
        reference
          // .orderBy("duration.start.timestamp", "asc")
          .limit(limit)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const object = {
                ...doc.data(),
                // Overwrite
              };
              reports.push(object);
            });
            return commit(SET_REPORTS, reports);
          })
          .catch((error) => console.log(error))
      );
    },
    [GET_ARCHIVED_REPORTS]({ commit }, payload) {
      const archivedReports = [];
      var limit = 800;
      if (payload.paginationLimit != undefined) {
        limit = payload.paginationLimit;
      }

      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("dutyReports")
        .where("status", "in", payload.query.status)
        // .where("type.id", "in", payload.query.types)
        // .where(
        //   "assignedTo.uid",
        //   "==",
        //   payload.query.assignedTo.uid
        // )
        .where(
          "duration.start.timestamp",
          ">=",
          fb.Timestamp.fromDate(new Date(payload.query.range.start))
        )
        .where(
          "duration.start.timestamp",
          "<=",
          fb.Timestamp.fromDate(new Date(payload.query.range.end))
        )
        .orderBy("duration.start.timestamp", "asc")
        .limit(limit)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const object = {
              ...doc.data(),
            };
            archivedReports.push(object);
          });
          console.log(archivedReports);
          return commit(SET_ARCHIVED_REPORTS, archivedReports);
        })
        .catch((error) => console.log(error));
    },

    [GET_REPORT_TYPES]({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const data = [];
        fb.organizationCollection
          .doc(payload.organizationId)
          .collection("dutyTypes")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              data.push(doc.data());
            });
            data.sort((a, b) => parseFloat(a.sortKey) - parseFloat(b.sortKey));
            commit(SET_REPORT_TYPES, data);
            resolve(querySnapshot);
          })
          .then(
            (response) => {
              resolve(response);
            },
            (error) => {
              console.log("Error getting document:", error);
              reject(error);
            }
          );
      });
    },

    [CREATE_REPORT_TYPE]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("dutyTypes")
        .doc();
      docRef
        .set({
          title: payload.title,
          shortTitle: payload.shortTitle,
          description: payload.description,
          icon: payload.icon,
          color: payload.color,
          sortKey: payload.sortKey,
          organizationId: payload.organizationId,
          prefill: {
            title: payload.prefill.title,
            location: payload.prefill.location,
            payrollType: {
              id: payload.prefill.payrollType.id,
              title: payload.prefill.payrollType.title,
            },
          },
          options: {
            requiredFields: payload.options.requiredFields,
            customFields: payload.options.customFields,
          },
          meta: {
            id: docRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    [CREATE_REPORT_TYPES]({ commit }, payload) {
      var batch = fb.db.batch();
      const batchRef = fb.db
        .collection("organizations")
        .doc(payload.organizationId)
        .collection("dutyTypes");

      payload.dutyTypes.forEach(function (dutyType) {
        const newDocRef = batchRef.doc();
        const object = {
          ...dutyType,
          organizationId: payload.organizationId,
          meta: {
            id: newDocRef.id,
            createdBy: fb.auth.currentUser.uid,
            createdAt: fb.FieldValue.serverTimestamp(),
          },
        };
        batch.set(newDocRef, object);
      });
      batch
        .commit()
        .then(() => {
          return commit(SET_LOADING, false);
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    [UPDATE_REPORT_TYPE]({ commit }, payload) {
      commit(SET_LOADING, true);
      fb.organizationCollection
        .doc(payload.organizationId)
        .collection("dutyTypes")
        .doc(payload.itemId)
        .update({
          title: payload.title,
          shortTitle: payload.shortTitle,
          description: payload.description,
          color: payload.color,
          icon: payload.icon,
          sortKey: payload.sortKey,
          "prefill.title": payload.prefill.title,
          "prefill.location": payload.prefill.location,
          "prefill.payrollType": payload.prefill.payrollType,
          "options.requiredFields": payload.options.requiredFields,
          "options.customFields": payload.options.customFields,

          "meta.lastUpdatedAt": fb.FieldValue.serverTimestamp(),
          "meta.lastUpdatedBy": fb.auth.currentUser.uid,
        })
        .catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },

    [DELETE_REPORT_TYPE]({ commit }, payload) {
      commit(SET_LOADING, true);
      const docRef = fb.organizationCollection
        .doc(payload.organizationId)
        .collection("dutyTypes")
        .doc(payload.itemId);
      docRef.delete().catch((error) => console.log(error));
      commit(SET_LOADING, false);
    },
  },

  getters: {},
};
